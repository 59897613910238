
import {Component, Vue} from 'vue-property-decorator'
import {getproduct, updateproduct, delproduct, addproduct, getCategory } from '../api/ApiPath'
import itemDetail from '@/components/itemDetail.vue';


@Component
export default class AdminProduct extends Vue{
[x: string]: any;

    addTableVisible = false;
    editTableVisible = false;
    formLabelWidth = '120px';
    category = [];
    productform = {
        product_id : 0,
        product_name : '',
        category_id : 0,
        product_title : '',
        product_intro: '',
        product_picture:'',
        product_price: 0.0,
        product_num: 0,
        product_sales: 0
    }
    
    tableData =[]
    init(){
        getproduct((res:any)=>{
            const {product, status, msg} = res;
            if(status === 200){
                this.tableData = product;
            }
        },(err:any)=>{this.$message.error(err)}) 
    }
    edit(row:any){
        this.productform = row
        this.editTableVisible = true
    }
    update(){
        this.editTableVisible = false;
        updateproduct(this.productform, (res)=>{
            this.init()
            let {status, msg} = res
            this.$message.success(msg)
        },(err)=>{this.$message.error(err)})

    }
    del(row:any){
        this.$confirm('确定删除该产品？','确定',{
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(()=>{
            delproduct(row, (res)=>{
                this.init()
                let {status,msg} = res
                this.$message.success(msg)
            },(err)=>{this.$message.error(err)})
            // this.$message.warning('目前暂不能删除，请和管理员联系')
        })

    }
    add(){
        this.addTableVisible = false;
        addproduct(this.productform, (res:any)=>{
            this.init()
            let {status, msg} = res
            this.$message.success(msg)
        },(err:any)=>{this.$message.error(err)})
    }
    created(){
        this.init()
    }

    formatCategory(row: any){
        if(this.category){
            let item = this.category.find(o=>{return o.category_id == row.category_id})
            return item&&item.category_name

        }
    }
    mounted(){
        getCategory((res:any)=>{
            this.category = res.category;
        },(err:any)=>{this.$message.error(err)})
    }
}
